import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Title, Column, Columns, Button, Level } from "bloomer"

import "./index.scss"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Container>
      <Columns isCentered>
        <Column isSize="1/2" className="home-left-column">
          <Title className="heading-text" isSize="1">
            Hey, I'm Pawel Marcinkiewicz.
          </Title>
          <Title className="subheading-text" isSize="3">
            I am a programmer, specialising in web development.
          </Title>
          <Title className="main-text" isSize="4">
            I have a passion for programming and learning new technologies,
            aside from personal projects I seek to solve problems with code and
            create dynamic user experiences.
          </Title>
          <br />
          <Level>
            <a href="https://github.com/paweljm" target="blank">
              <Button isColor="dark" className="is-large btn">
                GitHub &nbsp;
                <i className="fab fa-github" />
              </Button>
            </a>

            <a
              href="https://www.linkedin.com/in/pawel-marcinkiewicz-3a8ab8153/"
              target="blank"
            >
              <Button isColor="info" className="is-large mr-6 btn">
                LinkedIn &nbsp; <i className="fab fa-linkedin" />
              </Button>
            </a>
            <Link to="/portfolio">
              <Button isColor="light" isInverted className="is-large ml-6 btn">
                See My Work
              </Button>
            </Link>
          </Level>
        </Column>
        <Column className="test" isSize="1/3">
          <StaticImage
            id="portrait"
            className="is-pulled-right"
            src="../images/me_copy.jpg"
            width={300}
            backgroundColor="transparent"
            placeholder="none"
            quality={100}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Picture of me"
            style={{
              borderRadius: `50%`,
              boxShadow: "black 7.5px 5px 40px",
            }}
          />
        </Column>
      </Columns>
    </Container>
  </Layout>
)

export default IndexPage

//<StaticImage
//      src="../images/gatsby-astronaut.png"
//      width={300}
//      quality={95}
//      formats={["AUTO", "WEBP", "AVIF"]}
//      alt="A Gatsby astronaut"
//      style={{ marginBottom: `1.45rem` }}
//    />
